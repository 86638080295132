
.mb-0{
    padding-right: 150px;
}
.me-3{
    background-color: #AC544C;
}
.loginColor{
    color: #AC544C;
}
.btn-link {
    background-color: white transparent;
    padding-top: 25px;
}