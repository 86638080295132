.custom-bullets {
    list-style-type: none; /* Remove default bullet points */
    padding-left: 0; /* Optional: remove padding */
  }
  
  .custom-bullets li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 0; /* Optional: adjust padding */
  }
  